/* Unsupported Browser Styles */
.unsupported__container {
  height: 100vh;
  width: 100vw;
  background: #ededed;
  color: rgba(0, 0, 0, 0.87);
}

.unsupported__text {
  font-family: 'Segoe UI', 'Roboto', 'Helvetica', 'Arial', sans-serif;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 800px;
  color: #212121;
  padding: 8px;
}

.unsupported__text a,
.unsupported__text a:visited,
.unsupported__text a:active {
  color: #212121;
  font-weight: 700;
}

.unsupported__text > * {
  text-align: center;
}

.unsupported__text--heading {
  font-size: 1.6rem;
  margin-top: 0;
  margin-bottom: 12px;
  line-height: 1.2;
}

.unsupported__text--subheading {
  line-height: 1.51;
  font-size: 1.1rem;
}

/* Print Styles */
.print-only {
  display: none;
}

@media print {
  .print-only {
    width: 95%;
    margin: 0 auto;
    display: block !important;
    page-break-after: always;
  }

  body > *:not(.print-only) {
    display: none;
  }
}
.makeStyles-stepText-42 {
  font-size: 14px !important;
}

/* Scroll bar css */
/* width */
::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #e0e0e0;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #e0e0e0;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #e0e0e0;
}

body {
  background: #ededed !important;
}
